import {  createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    dashboard_data: {},
    dashboard_list: {},
    d_loading: false,
    l_loading: false,
  },
  extraReducers: (builder) => {
   },
});

export default dashboardSlice.reducer;
