import {
  Center,
  Container,
  Flex,
  Heading,
  Spacer,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TableView from "../../components/TableView";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import CustomInput from "../../components/CustomInput";
import {
  delete_plan_api,
  subs_plan_add_api,
  subscribe_list,
} from "../../utils/apis";
import { FiTrash2 } from "react-icons/fi";
import { toast } from "react-toastify";
import CustomButton from "../../components/CustomButton";
import { Modal } from "antd";
import CustomPopup from "../../components/CustomPopup";

const AllUserList = () => {
  const navigate = useNavigate();
  const [initialValue, setInitialValue] = useState(1);
  const [ShowAddPlan, setShowAddPlan] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setId] = useState("");
  const [addPlanData, setAddPlanData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  const [subsList, setSubsList] = useState([]);

  useEffect(() => {
    fetchdata();
  }, []);
  const fetchdata = async () => {
    try {
      setLoading1(true);
      const response = await subscribe_list(user?.consumer?.token);
      setLoading1(false);
      if (response.success) {
        setSubsList(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      setLoading1(false);
      console.log(error);
    }
  };

  const delete_plan = async () => {
    try {
      const response = await delete_plan_api(id, user?.consumer?.token);
      onClose();
      if (response.success) {
        toast.success(response.message);
        fetchdata();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const add_subs_plan = async () => {
    if (
      !addPlanData?.plan_name ||
      !addPlanData?.plan_duration ||
      !addPlanData?.pdf_limit ||
      !addPlanData?.plan_price
    ) {
      setAddPlanData({
        ...addPlanData,
        plan_nameError: !addPlanData?.plan_name ? "Plan Name is required" : "",
        plan_durationError: !addPlanData?.plan_duration
          ? "Plan Duration is required"
          : "",
        pdf_limitError: !addPlanData?.pdf_limit ? "Plan Limit is required" : "",
        plan_priceError: !addPlanData?.plan_price
          ? "Plan Price is required"
          : "",
      });
      return;
    }

    try {
      setLoading(true);
      const obj = {
        plan_name: addPlanData?.plan_name,
        pdf_limit: addPlanData?.pdf_limit,
        plan_price: addPlanData?.plan_price,
        plan_duration: addPlanData?.plan_duration,
        line1: !addPlanData?.line1 ? "" : addPlanData?.line1,
        line2: !addPlanData?.line2 ? "" : addPlanData?.line2,
        line3: !addPlanData?.line3 ? "" : addPlanData?.line3,
      };
      const response = await subs_plan_add_api(obj, user?.consumer?.token);
      setLoading(false);
      if (response.success) {
        toast(response?.message);
        fetchdata();
        setShowAddPlan(false);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justifyContent={"space-between"}>
        <Heading fontSize={"xl"}>All Plan List</Heading>
        <CustomButton
          title={"Add Plan"}
          bg={"#FFBF00"}
          onClick={() => {
            if (user?.consumer?.role == "super-admin") {
              setShowAddPlan(true);
            } else {
              toast.error("this page only of Admin Id");
            }
          }}
        />
      </Flex>
      <Spacer h={3} />
      <Flex gap={2} align={"center"} mb={1}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Plans:
        </Text>
        <Text fontWeight={"semibold"} fontSize={"xl"} color={"#000"}>
          {subsList?.length}
        </Text>
      </Flex>

      {loading1 ? (
        <Loader />
      ) : subsList.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Text>No Data Found</Text>
        </Center>
      ) : (
        <TableView
          headData={[
            "S.No.",
            "Plan Name",
            "Plan Price",
            "PDF Limit",
            "Plan Duration",
            "line1",
            "line2",
            "line3",
            "Actions",
          ]}
          body={subsList.map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + initialValue}</Td>
              <Td textAlign={"center"}>{v.plan_name}</Td>
              <Td textAlign={"center"}>Rs. {v.plan_price}</Td>
              <Td textAlign={"center"}>{v.pdf_limit}</Td>
              <Td textAlign={"center"}>{v.plan_duration}/Days</Td>
              <Td
                textAlign={"center"}
                minW={200}
                maxW={300}
                w={"full"}
                whiteSpace={"pre-wrap"}
              >
                <Tooltip>
                  {v.line1?.length == 0 ? "-----------" : v.line1}
                </Tooltip>
              </Td>
              <Td
                textAlign={"center"}
                minW={200}
                maxW={300}
                w={"full"}
                whiteSpace={"pre-wrap"}
              >
                {v.line2?.length == 0 ? "-----------" : v.line2}
              </Td>
              <Td
                textAlign={"center"}
                minW={200}
                maxW={300}
                w={"full"}
                whiteSpace={"pre-wrap"}
              >
                {v.line3?.length == 0 ? "-----------" : v.line3}
              </Td>
              <Td textAlign={"center"}>
                <Flex align={"center"} gap={2} justify={"center"}>
                  <Tooltip title={"Remove Policy"}>
                    <FiTrash2
                      size={20}
                      cursor={"pointer"}
                      onClick={() => {
                        setId(v?.plan_id);
                        onOpen();
                      }}
                    />
                  </Tooltip>
                </Flex>
              </Td>
            </Tr>
          ))}
        />
      )}
      <CustomPopup
        mess={"Are you sure? You want to delete Plan"}
        title={"Delete"}
        b_name={"Delete"}
        onClick={delete_plan}
        isOpen={isOpen}
        onClose={onClose}
      />
      {ShowAddPlan && (
        <Modal
          title={"Add Subscription Plan"}
          open={ShowAddPlan}
          footer={false}
          onCancel={() => setShowAddPlan(false)}
          width={"50%"}
        >
          <Flex gap={"1rem"}>
            <CustomInput
              label1={"Plan Name"}
              onlyone
              placeholder1={"Plan Name"}
              value1={addPlanData?.plan_name}
              onChange1={(e) =>
                setAddPlanData({
                  ...addPlanData,
                  plan_name: e.target.value,
                  plan_nameError: "",
                })
              }
              error1={addPlanData?.plan_nameError}
            />

            <CustomInput
              label1={"Plan Duration (in Days)"}
              onlyone
              placeholder1={"Plan Duration (in Days)"}
              value1={addPlanData?.plan_duration}
              onChange1={(e) => {
                const inputValue = e.target.value;
                if (/^\d{0,3}$/.test(inputValue)) {
                  setAddPlanData({
                    ...addPlanData,
                    plan_duration: inputValue,
                    plan_durationError: "",
                  });
                }
              }}
              error1={addPlanData?.plan_durationError}
            />
          </Flex>
          <Flex gap={"1rem"}>
            <CustomInput
              label1={"PDF Limit (in Number)"}
              onlyone
              maxL1={5}
              placeholder1={"PDF Limit"}
              value1={addPlanData?.pdf_limit}
              onChange1={(e) => {
                const inputValue = e.target.value
                  .trim()
                  .replace(/[^0-9 ]/g, "");
                setAddPlanData({
                  ...addPlanData,
                  pdf_limit: inputValue,
                  pdf_limitError: "",
                });
              }}
              error1={addPlanData?.pdf_limitError}
            />

            <CustomInput
              label1={"Plan Price (in Rs.)"}
              onlyone
              placeholder1={"Plan Price"}
              value1={addPlanData?.plan_price}
              onChange1={(e) => {
                const inputValue = e.target.value;
                if (/^\d{0,5}$/.test(inputValue)) {
                  setAddPlanData({
                    ...addPlanData,
                    plan_price: inputValue,
                    plan_priceError: "",
                  });
                }
              }}
              error1={addPlanData?.plan_priceError}
            />
          </Flex>
          <Flex gap={"1rem"}>
            <CustomInput
              label1={"Line1"}
              onlyone
              placeholder1={"Line1"}
              value1={addPlanData?.line1}
              onChange1={(e) => {
                const inputValue = e.target.value
                  .trimStart()
                  .replace(/^[0-9]/, "")
                  .replace(/\s{2,}/g, " ");
                setAddPlanData({
                  ...addPlanData,
                  line1: inputValue,
                  line1Error: "",
                });
              }}
              error1={addPlanData?.line1Error}
            />

            <CustomInput
              label1={"Line2"}
              onlyone
              placeholder1={"Line2"}
              value1={addPlanData?.line2}
              onChange1={(e) => {
                const inputValue = e.target.value
                  .trimStart()
                  .replace(/^[0-9]/, "")
                  .replace(/\s{2,}/g, " ");

                setAddPlanData({
                  ...addPlanData,
                  line2: inputValue,
                  line2Error: "",
                });
              }}
              error1={addPlanData?.line2Error}
            />
          </Flex>
          <Flex gap={"1rem"}>
            <CustomInput
              label1={"Line3"}
              onlyone
              placeholder1={"Line3"}
              value1={addPlanData?.line3}
              onChange1={(e) => {
                const inputValue = e.target.value
                  .trimStart()
                  .replace(/^[0-9]/, "")
                  .replace(/\s{2,}/g, " ");
                setAddPlanData({
                  ...addPlanData,
                  line3: inputValue,
                  line3Error: "",
                });
              }}
              error1={addPlanData?.line3Error}
            />

            <Flex flex={1} />
          </Flex>
          <Flex m={3} justifyContent={"center"}>
            <CustomButton
              title={"Submit"}
              bg={"#FFBF00"}
              loading={loading}
              onClick={() => add_subs_plan()}
            />
          </Flex>
        </Modal>
      )}
    </Container>
  );
};

export default AllUserList;
