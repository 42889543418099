import {
  Container,
  Flex,
  Heading,
  Link,
  Spacer,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TableView from "../../components/TableView";
import store from "../../redux/store";
import Loader from "../../components/Loader";
import { getUsers } from "../../redux/slice/usersSlice";
import TableHeader from "../../components/TableHeader";
import { exportPDF } from "../../utils/utils";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

const UsersList = () => {
  const [initialValue, setInitialValue] = useState(1);
  const navigate = useNavigate();
  const { users_list, u_loading, total_users, total_users_list } = useSelector(
    (state) => state.users
  );
  const [rows, setRows] = useState(10);
  const [page, setpage] = useState(1);
  const [value, setValue] = useState("All");
  const [search, setSearch] = useState("");
  const [listData, setlistData] = useState([]);
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  useEffect(() => {
    store
      .dispatch(getUsers(user?.consumer?.token))
      .unwrap()
      .then((v) => {
        if (v.success) {
          setlistData(v?.data);
        }
      });
  }, []);
  useEffect(() => {
    setlistData(users_list);
  }, [users_list]);

  useEffect(() => {
    handleInputChange();
  }, [search]);

  const handleInputChange = () => {
    if (value === "All") {
      setpage(1);
      const inputText = search.toLowerCase();
      const filteredResults = users_list.filter((item) =>
        Object.values(item).some((val) =>
          String(val).toLowerCase().includes(inputText)
        )
      );
      setlistData(filteredResults);
    } else if (value === "Name") {
      const filterData = users_list.filter((user) =>
        user.full_name.toLowerCase().includes(search.toLowerCase())
      );
      setlistData(filterData);
    } else if (value === "Email") {
      const filterData = users_list.filter((user) =>
        user.email_address.toLowerCase().includes(search.toLowerCase())
      );
      setlistData(filterData);
    } else if (value === "Mobile") {
      const filterData = users_list.filter((user) =>
        user.mobile.toLowerCase().includes(search.toLowerCase())
      );
      setlistData(filterData);
    }
  };

  const headers = [
    { label: "S.No.", key: "no" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Mobile", key: "mobile" },
    { label: "Platform", key: "platform" },
    { label: "Document", key: "document" },
  ];
  const data = users_list.map((elt, i) => ({
    no: i + 1,
    name: elt.full_name,
    email: elt.email_address,
    mobile: elt.mobile,
    policies: 10,
  }));

  const table_data = users_list.map((elt, i) => [
    i + 1,
    elt.full_name,
    elt.email_address,
    elt.mobile,
    10,
  ]);

  const export_pdf = () => {
    exportPDF({
      table_data: table_data,
      table_headers: ["S.No.", "Name", "Email", "Mobile", "Policies"],
      fileName: "user-list",
      tableName: "SD 365 User List",
    });
  };

  const handleExcel = (data) => {
    if (data?.length > 0) {
      const ws = XLSX.utils.json_to_sheet(data.map((v) => v));

      // Check if ws["!ref"] is defined before using it
      const firstRowRange = ws["!ref"]
        ? XLSX.utils.decode_range(ws["!ref"])
        : null;

      if (firstRowRange) {
        for (let col = firstRowRange.s.c; col <= firstRowRange.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({
            r: firstRowRange.s.r,
            c: col,
          });
          ws[cellAddress].s = { fill: { fgColor: { rgb: "DDDDDD" } } }; // Gray color
        }

        for (let col = firstRowRange.s.c; col <= firstRowRange.e.c; col++) {
          let maxCellLength = 30;

          for (
            let row = firstRowRange.s.r + 1;
            row <= firstRowRange.e.r;
            row++
          ) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            const cellValue = ws[cellAddress] ? ws[cellAddress].w || "" : "";
            maxCellLength = Math.max(maxCellLength, cellValue.length);
          }

          const colWidth = maxCellLength + 1; // You can adjust this value based on your needs
          ws["!cols"] = ws["!cols"] || [];
          ws["!cols"][col] = { width: colWidth };
        }

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
          mimeType: "application/octet-stream",
        });
        const blob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        saveAs(blob, "your_file_name.xlsx");
      } else {
        toast.error("Please Add Some Item");
      }
    }
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Heading fontSize={"xl"}>Users List</Heading>
      <Spacer h={3} />
      <Flex gap={2} align={"center"} mb={1}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Users:
        </Text>
        <Text fontWeight={"semibold"} fontSize={"xl"} color={"#000"}>
          {total_users}
        </Text>
      </Flex>
      <TableHeader
        CSVdata={data}
        headers={headers}
        filename={"user-list.csv"}
        onPdfClick={() => export_pdf()}
        data={["All", "Name", "Email", "Mobile", "Blocked"]}
        onChange={(v) => {
          setValue(v.target.value);
          setSearch("");
          if (v.target.value == "Blocked") {
            const filterData = users_list.filter((v) => v?.blocked);
            setlistData(filterData);
          } else {
            setlistData(users_list);
          }
        }}
        value={search}
        onSerchChange={(v) => {
          setpage(1);
          setSearch(v.target.value);
        }}
        handleExcel={() => handleExcel(listData)}
      />

      {u_loading && listData.length === 0 ? (
        <Loader />
      ) : listData.length == 0 ? (
        <p
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            fontSize: "25px",
            fontWeight: "700",
          }}
        >
          No Data Found
        </p>
      ) : (
        <TableView
          headData={["S.No.", "Name", "Email", "mobile", "policies"]}
          body={listData?.slice(rows * page - rows, rows * page).map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + initialValue}</Td>
              <Td textAlign={"center"}>
                <Link
                  onClick={() => navigate("user-detail", { state: v })}
                  textTransform={"capitalize"}
                >
                  {v.full_name}
                </Link>
              </Td>
              <Td textAlign={"center"}>{v.email_address}</Td>
              <Td textAlign={"center"}>{v.mobile}</Td>
              <Td textAlign={"center"}>
                {v?.pdfInfo?.length || "Not Avalible Policy"}
              </Td>
            </Tr>
          ))}
          total_value={listData?.length || users_list?.length}
          pagination
          initialValue={initialValue}
          onChange={(v) => {
            setRows(v.target.value);
            store.dispatch(getUsers(user?.consumer?.token));
            setInitialValue(1);
            setpage(1);
          }}
          rows={rows}
          page={page}
          onPrevious={() => {
            initialValue == 1 ||
              setInitialValue(Number(initialValue) - Number(rows));
            page == 1 || setpage(page - 1);
            page == 1 || store.dispatch(getUsers(user?.consumer?.token));
          }}
          onNext={() => {
            rows * page >= total_users ||
              setInitialValue(Number(initialValue) + Number(rows));
            rows * page >= total_users || setpage(page + 1);
            rows * page >= total_users ||
              store.dispatch(getUsers(user?.consumer?.token));
          }}
        />
      )}
    </Container>
  );
};

export default UsersList;
