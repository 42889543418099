import { toast } from "react-toastify";
import { ID, base_url } from "./utils";
import moment from "moment";
import store from "../redux/store";
import { getUsers } from "../redux/slice/usersSlice";
const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);

export const login_api = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "login/", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    });
    const res = await response.json();

    if (res.success) {
      setLoading(false);
      if (
        res?.consumer?.role == "admin" ||
        res?.consumer?.role == "super-admin"
      ) {
        toast.success("Logged In Successfully");
        store.dispatch(getUsers(res?.consumer?.token));
        localStorage.setItem("userId", JSON.stringify(res));
        window.location.reload();
        return res;
      } else {
        toast.error("Invalid Credential");
        return res;
      }
    } else {
      setLoading(false);
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const add_admin_api = async (body) => {
  try {
    const response = await fetch(base_url + "user/register/admin", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.consumer?.token}`,
      },
      method: "POST",
      body: JSON.stringify(body),
    });
    const res = await response.json();
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const delete_admin_api = async (body) => {
  try {
    const response = await fetch(base_url + "user/delete-admin", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.consumer?.token}`,
      },
      method: "POST",
      body: JSON.stringify(body),
    });
    const res = await response.json();
    console.log(res);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const get_user_chart_data_api = async (sDate, eDate) => {
  try {
    const response = await fetch(
      `${base_url}report/user?from_date=${moment(eDate).format(
        "YYYY/MM/DD"
      )}&to_date=${moment(sDate).format("YYYY/MM/DD")}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.consumer?.token}`,
        },
      }
    );
    const res = await response.json();
    return res;
  } catch (error) {
    console.error(error);
  }
};

// User Data Chart

export const get_policy_chart_data_api = async (sDate, eDate) => {
  try {
    const response = await fetch(
      `${base_url}report/policy?from_date=${eDate}&to_date=${sDate}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.consumer?.token}`,
        },
      }
    );
    const res = await response.json();
    return res;
  } catch (error) {
    console.error(error);
  }
};

// Block User API

export const block_user_api = async (body, token) => {
  try {
    const response = await fetch(base_url + "user/block", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.consumer?.token}`,
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    store.dispatch(getUsers(user?.consumer?.token));
    console.log(res);
    return res;
  } catch (error) {
    console.log(error);
  }
};

// Send Nortification API

export const send_nortification_api = async (body, token) => {
  try {
    const response = await fetch(base_url + "admin-notification/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.consumer?.token}`,
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const query_status_update_api = async (body, token) => {
  try {
    const response = await fetch(base_url + "questions/query/status", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.consumer?.token}`,
      },
      body: JSON.stringify(body),
    });
    const res = await response.json();
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const get_user_plan = async (id) => {
  try {
    const response = await fetch(
      base_url + "subcription-plan/get/specific-plan-detail/" + id,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user?.consumer?.token}`,
        },
      }
    );
    const res = await response.json();
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const subscribe_list = async (token) => {
  try {
    const response = await fetch(base_url + "subcription-plan/list", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const delete_plan_api = async (id, token) => {
  try {
    const response = await fetch(
      base_url + "subcription-plan/delete-plan-id/" + id,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const res = await response.json();
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const subs_plan_add_api = async (data, token) => {
  try {
    const response = await fetch(base_url + "subcription-plan/create", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      body: JSON.stringify(data),
    });
    const res = await response.json();
    return res;
  } catch (error) {}
};

// Blogs API

export const blogs_list_api = async (token) => {
  try {
    const response = await fetch(base_url + "blogs/list", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const blogs_add_api = async (data, token) => {
  try {
    const response = await fetch(base_url + "blogs/create", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      body: data,
    });
    const res = await response.json();
    return res;
  } catch (error) {}
};

export const delete_blog_api = async (id, token) => {
  try {
    const response = await fetch(base_url + "blogs/delete-blog-id/" + id, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();
    return res;
  } catch (error) {
    console.log(error);
  }
};
