import {
  Box,
  Card,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  Stack,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserChart from "./UserChart";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import CustomButton from "../../components/CustomButton";
import PolicyChart from "./PolicyChart";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  get_policy_chart_data_api,
  get_user_chart_data_api,
} from "../../utils/apis";
import store from "../../redux/store";
import { getUsers } from "../../redux/slice/usersSlice";

const Dashboard = () => {
  const { dashboard_data, dashboard_list } = useSelector(
    (state) => state.dashboard
  );
  const { users_list, u_loading, total_users, total_users_list } = useSelector(
    (state) => state.users
  );
  console.log(users_list?.filter((v) => v.blocked));
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  const date = new Date();
  date.setDate(date.getDate() - 7);
  const date1 = new Date();
  date1.setDate(date1.getDate());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setdata] = useState([]);
  const [data2, setdata2] = useState([]);
  const [todayDate, setTodayDate] = useState(date1);
  const [daysAgoDate, setDaysAgoDate] = useState(date);

  const [obj, setObj] = useState({});

  useEffect(() => {
    store.dispatch(getUsers(user?.consumer?.token));
    fetchData();
    fetchData2();
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [users_list]);

  const fetchUserData = () => {
    if (users_list.length > 0) {
      let totalPolicy = 0;
      let blockedUser = 0;
      let totalActiveUser = 0;

      for (let i = 0; i < users_list.length; i++) {
        console.log(users_list[i]?.blocked);
        if (!users_list[i]?.blocked) {
          totalActiveUser += 1;
        } else {
          blockedUser += 1;
        }

        if (users_list[i]?.pdfInfo) {
          totalPolicy += users_list[i]?.pdfInfo?.length || 0;
        }
      }
      setObj({
        totalPolicy: totalPolicy,
        totalActiveUser: totalActiveUser,
        blockedUser: blockedUser,
      });

      // return { blockedUser, totalActiveUser, totalPolicy };
    }
  };
  // console.log(fetchUserData().totalPolicy);

  useEffect(() => {
    fetchData();
    fetchData2();
  }, [todayDate, daysAgoDate]);

  const fetchData = async () => {
    try {
      const response = await get_user_chart_data_api(todayDate, daysAgoDate);
      if (response.success) {
        setdata(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData2 = async () => {
    try {
      const response = await get_policy_chart_data_api(todayDate, daysAgoDate);
      if (response.success) {
        setdata2(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const selectionRange = {
    startDate: daysAgoDate,
    endDate: todayDate,
    key: "selection",
  };

  return (
    <Container maxH={"full"} maxW={"container"}>
      <SimpleGrid minChildWidth="xs" spacing="10px">
        <Card bg="#fff" p={5} borderRadius={5}>
          {/* {u_loading && users_list?.length == 0 ? (
            <Stack
              w={"full"}
              h={"24"}
              borderRadius={10}
              alignItems={"center"}
              justify={"center"}
              bg={"AppWorkspace"}
            >
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Stack>
          ) : ( */}
          <>
            <Heading fontSize={"xl"}>Users</Heading>
            <Text>Total Users: {total_users}</Text>
            <Text>Active Users: {obj?.totalActiveUser}</Text>
            {/* <Text>Active Users: {fetchUserData()?.totalActiveUser}</Text> */}
            <Text>Blocked Users: {obj?.blockedUser}</Text>
            {/* <Text>Blocked Users: {fetchUserData()?.blockedUser}</Text> */}
          </>
          {/* )} */}
        </Card>
        <Card bg="#fff" p={5} borderRadius={5}>
          <Heading fontSize={"xl"}>Policies</Heading>
          <Text>Total Policies: {obj?.totalPolicy}</Text>
          {/* <Text>Total Policies: {fetchUserData()?.totalPolicy}</Text> */}
        </Card>
      </SimpleGrid>
      <Flex
        flexDirection={["column", "row"]}
        alignItems={["start", "center"]}
        justify={"end"}
        mt={2}
      >
        <Text mr={".5rem"}>Select Date : </Text>
        <Box
          borderWidth={1}
          maxW={"lg"}
          paddingBlock={2}
          paddingInline={5}
          flexDirection={"row"}
          display={"flex"}
          gap={5}
          cursor={"pointer"}
          borderRadius={4}
          onClick={() => onOpen()}
          bg={"#fff"}
        >
          <Text>
            {daysAgoDate
              ? moment(daysAgoDate).format("YYYY-MM-DD")
              : "Start date"}
          </Text>
          :
          <Text>
            {todayDate ? moment(todayDate).format("YYYY-MM-DD") : "End date"}
          </Text>
        </Box>
      </Flex>
      <Modal
        isOpen={isOpen}
        onClick={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"2xl"}>
          <ModalBody>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={(v) => {
                setDaysAgoDate(v.selection.startDate);
                setTodayDate(v.selection.endDate);
              }}
            />
            {/* <DateRangePicker
              ranges={[
                {
                  startDate: daysAgoDate,
                  endDate: todayDate,
                  key: "selection",
                },
              ]}
              onChange={(v) => {
                setDaysAgoDate(
                  moment(v.selection.startDate).format("YYYY/MM/DD")
                );
                setTodayDate(moment(v.selection.endDate).format("YYYY/MM/DD"));
              }}
            /> */}
          </ModalBody>
          <ModalFooter>
            <CustomButton
              title={"Ok"}
              onClick={() => {
                onClose();
              }}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <UserChart data={data} />
      <PolicyChart
        data={data2}
        data1={[
          { name: "Policy", data: dashboard_list.policies },
          { name: "Policy expire", data: dashboard_list.policiesexpire },
        ]}
      />
    </Container>
  );
};

export default Dashboard;
