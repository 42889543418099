import {
  Center,
  Container,
  Flex,
  Heading,
  Spacer,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TableView from "../../components/TableView";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import store from "../../redux/store";

import CustomPopup from "../../components/CustomPopup";
import CustomInput from "../../components/CustomInput";
import { add_admin_api, delete_admin_api } from "../../utils/apis";
import { FiTrash2 } from "react-icons/fi";
import { toast } from "react-toastify";
import moment from "moment";
import CustomButton from "../../components/CustomButton";
import { Modal } from "antd";
import { GoEye, GoEyeClosed } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { getAdmin } from "../../redux/slice/usersSlice";
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const AddAdmin = () => {
  const [initialValue, setInitialValue] = useState(1);
  const [ShowAddPlan, setShowAddPlan] = useState(false);
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [addData, setAddData] = useState({
    first_name: "",
    last_name: "",
    email_address: "",
    mobile: "",
    pass: "",
    confpass: "",
  });
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  const role = user?.consumer?.role;
  const { admin_list, admin_loading } = useSelector((state) => state.users);
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (role != "super-admin") {
      navigate("/");
      toast.error("this page only of Admin Id", { toastId: "1" });
    }
    store.dispatch(getAdmin(user?.consumer?.token));
  }, []);

  const add_admin = async () => {
    if (
      !addData?.first_name ||
      addData?.first_name.length === 0 ||
      !addData?.last_name ||
      addData?.last_name.length === 0 ||
      !addData?.mobile ||
      addData?.mobile.length === 0 ||
      !/^[0-9]{10}$/.test(addData?.mobile) ||
      !addData?.email ||
      addData?.email.length === 0 ||
      !emailRegex.test(addData?.email) ||
      !addData?.pass ||
      addData?.pass.length === 0 ||
      !addData?.confpass ||
      addData?.confpass.length === 0 ||
      addData.pass != addData?.confpass
    ) {
      setAddData((prevData) => ({
        ...prevData,
        nameError:
          !prevData?.first_name || prevData?.first_name?.length === 0
            ? "Please Enter First Name"
            : "",
        name2Error:
          !prevData?.last_name || prevData?.last_name.length === 0
            ? "Please Enter Last Name"
            : "",
        MobileError:
          !prevData?.mobile || prevData?.mobile.length === 0
            ? "Please Enter Mobile"
            : prevData?.mobile.length !== 10 ||
              !/^[0-9]{10}$/.test(prevData?.mobile)
            ? "Please Enter Valid Mobile"
            : "",
        emailError:
          !prevData?.email || prevData?.email.length === 0
            ? "Please Enter Email"
            : !emailRegex.test(prevData?.email)
            ? "Please Enter Valid Email"
            : "",
        passError:
          !prevData?.pass || prevData?.pass.length === 0
            ? "Please Enter Password"
            : prevData?.pass.length < 8 && prevData?.pass.length > 16
            ? "Password should be 8-16 digit"
            : "",
        confPassError:
          !prevData?.confpass || prevData?.confpass.length === 0
            ? "Please Enter Confirm Password"
            : addData?.confpass != addData?.pass
            ? "Passwords not match"
            : "",
      }));
      return;
    }
    try {
      setLoading(true);
      const obj = {
        first_name: addData?.first_name,
        last_name: addData?.last_name,
        email_address: addData?.email,
        mobile: addData?.mobile,
        password: addData?.pass,
      };
      const response = await add_admin_api(obj);
      setLoading(false);
      if (response.success) {
        setAddData({});
        setShowAddPlan(false);
        toast.success(response.message, {
          toastId: "1",
        });
        store.dispatch(getAdmin(user?.consumer?.token));
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onDelete = async () => {
    setLoading(true);
    const body = {
      user_id: id,
    };
    const res = await delete_admin_api(body);
    setLoading(false);
    onClose();
    if (res.success) {
      store.dispatch(getAdmin(user?.consumer?.token));
      toast.success(res.message);
    } else {
      toast.error(res.message);
    }
  };

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justifyContent={"space-between"}>
        <Heading fontSize={"xl"}>All Admin Data</Heading>
        <CustomButton
          title={"Add Admin"}
          bg={"#FFBF00"}
          onClick={() => {
            setAddData({});
            setShowAddPlan(true);
          }}
        />
      </Flex>
      <Spacer h={3} />
      <Flex gap={2} align={"center"} mb={1}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Admin:
        </Text>
        <Text fontWeight={"semibold"} fontSize={"xl"} color={"#000"}>
          {admin_list?.length}
        </Text>
      </Flex>
      {admin_loading && admin_list?.length === 0 ? (
        <Loader />
      ) : admin_list?.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Text
            style={{ fontSize: "20px", marginTop: "5rem", height: "100px" }}
          >
            No Data
          </Text>
        </Center>
      ) : (
        <TableView
          headData={[
            "S.No.",
            "Name",
            "Email",
            "Mobile",
            "created at",
            "Actions",
          ]}
          body={admin_list?.map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + initialValue}</Td>
              <Td textAlign={"center"}>{v.full_name}</Td>
              <Td textAlign={"center"}>{v.email_address}</Td>
              <Td textAlign={"center"}>
                {/* <img src={v?.imageUrl} alt="img" width="200px" height="200px" /> */}
                {v?.mobile}
              </Td>
              <Td textAlign={"center"}>
                {moment(v.created_at).format("DD/MM/YYYY")}
              </Td>
              <Td textAlign={"center"}>
                {
                  <Flex align={"center"} gap={2} justify={"center"}>
                    <Tooltip title={"Remove Policy"}>
                      <FiTrash2
                        size={20}
                        cursor={"pointer"}
                        onClick={() => {
                          setId(v.user_id);
                          onOpen();
                        }}
                      />
                    </Tooltip>
                  </Flex>
                }
              </Td>
            </Tr>
          ))}
        />
      )}
      <CustomPopup
        mess={"Are you sure? You want to delete admin"}
        title={"Delete"}
        b_name={"Delete"}
        onClick={onDelete}
        isOpen={isOpen}
        onClose={onClose}
      />
      {ShowAddPlan && (
        <Modal
          title={"Add Admin"}
          open={ShowAddPlan}
          footer={false}
          onCancel={() => setShowAddPlan(false)}
          width={"55%"}
        >
          <CustomInput
            label1={"First Name"}
            placeholder1={"First Name"}
            value1={addData?.first_name}
            onChange1={(e) =>
              setAddData({
                ...addData,
                first_name: e.target.value
                  .trimStart()
                  .replace(/[^a-zA-Z0-9 ]/g, "")
                  .replace(/^[0-9]/, "")
                  .replace(/\s{2,}/g, " "),
                nameError: "",
              })
            }
            error1={addData?.nameError}
            label2={"Last Name"}
            placeholder2={"Last Name"}
            value2={addData?.last_name}
            onChange2={(e) =>
              setAddData({
                ...addData,
                last_name: e.target.value
                  .trimStart()
                  .replace(/[^a-zA-Z0-9 ]/g, "")
                  .replace(/^[0-9]/, "")
                  .replace(/\s{2,}/g, " "),
                name2Error: "",
              })
            }
            error2={addData?.name2Error}
          />
          <CustomInput
            label1={"Email"}
            placeholder1={"Email"}
            value1={addData?.email}
            onChange1={(e) => {
              const inputValue = e.target.value.trim();
              setAddData({ ...addData, email: inputValue, emailError: "" });
            }}
            error1={addData?.emailError}
            label2={"Mobile"}
            placeholder2={"Mobile"}
            value2={addData?.mobile}
            maxL2={10}
            onChange2={(e) => {
              const inputValue = e.target.value;
              const numericValue = inputValue.replace(/\D/g, "");
              const mobileValue = numericValue.slice(0, 10);
              setAddData({ ...addData, mobile: mobileValue, MobileError: "" });
            }}
            error2={addData?.MobileError}
          />
          <CustomInput
            label1={"Password"}
            placeholder1={"Password"}
            value1={addData?.pass}
            onChange1={(e) =>
              setAddData({
                ...addData,
                pass: e.target.value.trim(),
                passError: "",
              })
            }
            type1={!showPass1 ? "password" : "text"}
            right1={
              !showPass1 ? (
                <GoEye
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPass1(true);
                  }}
                />
              ) : (
                <GoEyeClosed
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPass1(false);
                  }}
                />
              )
            }
            error1={addData?.passError}
            type2={!showPass2 ? "password" : "text"}
            right2={
              !showPass2 ? (
                <GoEye
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPass2(true);
                  }}
                />
              ) : (
                <GoEyeClosed
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowPass2(false);
                  }}
                />
              )
            }
            error2={addData?.confPassError}
            label2={"Confirm Password"}
            placeholder2={"Confirm Password"}
            value2={addData?.confpass}
            onChange2={(e) =>
              setAddData({
                ...addData,
                confpass: e.target.value.trim(),
                confPassError: "",
              })
            }
          />
          <Flex m={3} justifyContent={"center"}>
            <CustomButton
              title={"Add"}
              bg={"#FFBF00"}
              loading={loading}
              onClick={() => add_admin()}
            />
          </Flex>
        </Modal>
      )}
    </Container>
  );
};
