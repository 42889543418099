import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";
import store from "../store";
const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);

export const getPolicylist = createAsyncThunk("policy", async (token) => {
  const response = await fetch(base_url + "subcription-plan/list", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const res = await response.json();

  return res;
});



const policylistSlice = createSlice({
  name: "policy",
  initialState: {
    policies_list: [],
    p_loading: false,
    all_loading: false,
    all_users_policy: [],
    total_policies_list: [],
    total_policies: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(getPolicylist.pending, (state, action) => {
      state.p_loading = true;
    });
    builder.addCase(getPolicylist.rejected, (state, action) => {
      state.p_loading = false;
    });
    builder.addCase(getPolicylist.fulfilled, (state, action) => {
      const data = action.payload;
      state.p_loading = false;
      if (data.success) {
        state.policies_list = data.data;
      } else {
        state.policies_list = [];
      }
    });

  },
});

export default policylistSlice.reducer;
