import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ID, base_url } from "../../utils/utils";

const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    employee_list: [],
    e_loading: false,
  },
  extraReducers: (builder) => {
   
  },
});

export default employeeSlice.reducer;
