import { Card, Container, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import { useSelector } from "react-redux";

const PolicyChart = ({ data }) => {
  const totalPolicy = data.reduce((total, item) => total + item.count, 0);
  return (
    <>
      <Heading fontSize={"xl"} mt={4}>
        Policy Status
      </Heading>
      <Flex flexDirection={["column", "row"]} gap={3} mt={2}>
        <Container maxW={"full"} h={"sm"} p={0}>
          <Card h={"sm"} p={2}>
            <Text fontSize={"md"} mb={2}>
              Total Policies:{totalPolicy}
            </Text>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#007BC9" />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Container>
        <Container maxW={"full"} h={"sm"} p={0}>
          <Card h={"sm"} p={2}>
            <Text fontSize={"md"} mb={2}>
              Total Policies:{totalPolicy}
            </Text>
            <ResponsiveContainer
              width="100%"
              height="100%"
              // maxHeight={"100%"}
              // minWidth={"100%"}
            >
              <LineChart
                data={data}
                margin={
                  {
                    // top: 5,
                    // right: 30,
                    // left: 20,
                    // bottom: 5,
                  }
                }
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="count"
                  stroke="#007BC9"
                  activeDot={{ r: 5 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Container>
      </Flex>
    </>
  );
};

export default PolicyChart;
