import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Spacer,
  Td,
  Text,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import TableView from "../../components/TableView";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import CustomPopup from "../../components/CustomPopup";
import CustomInput from "../../components/CustomInput";
import { blogs_add_api, delete_blog_api } from "../../utils/apis";
import { FiTrash2 } from "react-icons/fi";
import { toast } from "react-toastify";
import moment from "moment";
import CustomButton from "../../components/CustomButton";
import { Modal } from "antd";
import store from "../../redux/store";
import { getBlogs } from "../../redux/slice/blogSlice";
import SelectImage from "../../components/SelectImage";
export const upload_url = "https://api.smartdocs365.com/";

const Blogs = () => {
  const navigate = useNavigate();
  const [initialValue, setInitialValue] = useState(1);
  const [ShowAddPlan, setShowAddPlan] = useState(false);
  const [rows, setRows] = useState(10);
  const [page, setpage] = useState(1);
  const { blogs_list, blogs_loading } = useSelector((state) => state.blogs);
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  const [BlogsData, setBlogsData] = useState({});
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setId] = useState("");

  useEffect(() => {
    store.dispatch(getBlogs());
  }, []);

  const add_blogs = async () => {
    if (!BlogsData?.title || !BlogsData?.desc) {
      setBlogsData({
        ...BlogsData,
        titleMess: !BlogsData?.title ? "Title is required" : "",
        descMess: !BlogsData?.desc ? "Description is required" : "",
      });
      return;
    }

    try {
      setLoading(true);
      const body = new FormData();
      body.append("title", BlogsData?.title);
      body.append("description", BlogsData?.desc);
      body.append("videoUrl", !BlogsData?.videoUrl ? "" : BlogsData?.videoUrl);
      body.append("image", !BlogsData?.img ? "" : BlogsData?.img);
      const response = await blogs_add_api(body, user?.consumer?.token);
      setLoading(false);
      if (response.success) {
        toast.success(response.message);
        setShowAddPlan(false);
        store.dispatch(getBlogs());
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const delete_blogs = async () => {
    try {
      const response = await delete_blog_api(id, user?.consumer?.token);
      if (response.success) {
        store.dispatch(getBlogs());
        onClose();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justifyContent={"space-between"}>
        <Heading fontSize={"xl"}>All Blogs</Heading>
        <CustomButton
          title={"Add Blogs"}
          bg={"#FFBF00"}
          onClick={() => setShowAddPlan(true)}
        />
      </Flex>
      <Spacer h={3} />
      <Flex gap={2} align={"center"} mb={1}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Blogs:
        </Text>
        <Text fontWeight={"semibold"} fontSize={"xl"} color={"#000"}>
          {blogs_list?.length}
        </Text>
      </Flex>

      {blogs_loading ? (
        <Loader />
      ) : blogs_list.length === 0 ? (
        <Center bg={"#fff"} borderRadius={10}>
          <Text>No Data</Text>
        </Center>
      ) : (
        <TableView
          headData={[
            "S.No.",
            "Title",
            "Description",
            "Image",
            "Video",
            "Update Date",
            "Actions",
          ]}
          body={blogs_list.map((v, i) => (
            <Tr key={i}>
              <Td textAlign={"center"}>{i + initialValue}</Td>
              <Td textAlign={"center"}>{v.title}</Td>
              <Td
                textAlign={"center"}
                maxW={"400px"}
                whiteSpace={"pre-wrap"}
                isNumeric={12}
              >
                {v.description}
              </Td>
              <Td textAlign={"center"}>
                {!v?.imageUrl ? (
                  "-----"
                ) : (
                  <Image
                    src={`${upload_url}${v?.imageUrl}`}
                    alt="img"
                    w="300px"
                    h="200px"
                    objectFit={"contain"}
                  />
                )}
              </Td>
              <Td textAlign={"center"}>
                {v?.videoUrl?.length == 0 ? (
                  "-----"
                ) : (
                  <iframe
                    width="200px"
                    height="200px"
                    src={v?.videoUrl}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                )}
              </Td>
              <Td textAlign={"center"}>
                {moment(v.updated_at).format("DD/MM/YYYY")}
              </Td>
              <Td textAlign={"center"}>
                {
                  <Flex align={"center"} gap={2} justify={"center"}>
                    <Tooltip title={"Remove Policy"}>
                      <FiTrash2
                        size={20}
                        cursor={"pointer"}
                        onClick={() => {
                          setId(v?.blog_id);
                          onOpen();
                        }}
                      />
                    </Tooltip>
                  </Flex>
                }
              </Td>
            </Tr>
          ))}
          pagination={true}
          rows={rows}
          page={page}
          initialValue={initialValue}
          onChange={(v) => {
            setRows(v.target.value);
            setInitialValue(1);
            setpage(1);
          }}
          total_value={blogs_list?.length}
        />
      )}

      {ShowAddPlan && (
        <Modal
          title={"Add Blogs"}
          open={ShowAddPlan}
          footer={false}
          onCancel={() => setShowAddPlan(false)}
          width={"50%"}
        >
          <Flex gap={"1rem"}>
            <CustomInput
              label1={"Title"}
              onlyone
              placeholder1={"Title"}
              value1={BlogsData?.title}
              onChange1={(e) =>
                setBlogsData({
                  ...BlogsData,
                  title: e.target.value,
                  titleMess: "",
                })
              }
              error1={BlogsData?.titleMess}
            />
          </Flex>
          <Flex gap={"1rem"}>
            <CustomInput
              label1={"Description"}
              onlyone
              placeholder1={"Description"}
              value1={BlogsData?.desc}
              onChange1={(e) =>
                setBlogsData({
                  ...BlogsData,
                  desc: e.target.value,
                  descMess: "",
                })
              }
              error1={BlogsData?.descMess}
            />
          </Flex>
          <Flex gap={"1rem"}>
            <CustomInput
              label1={"Video Url (Optional)"}
              onlyone
              placeholder1={"Video Url"}
              value1={BlogsData?.videoUrl}
              onChange1={(e) =>
                setBlogsData({ ...BlogsData, videoUrl: e.target.value })
              }
            />
          </Flex>
          <Flex gap={"1rem"}>
            <SelectImage
              label={"Image (Optional)"}
              onlyone
              filename={BlogsData?.img?.name}
              onChange={(e) => {
                const file = e.target.files[0];
                setBlogsData({ ...BlogsData, img: file });
              }}
            />
          </Flex>
          <Flex m={3} justifyContent={"center"}>
            <CustomButton
              title={"Submit"}
              bg={"#FFBF00"}
              loading={loading}
              onClick={() => add_blogs()}
            />
          </Flex>
        </Modal>
      )}
      <CustomPopup
        mess={"Are you sure? You want to delete Blog"}
        title={"Delete"}
        b_name={"Delete"}
        onClick={delete_blogs}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Container>
  );
};

export default Blogs;
