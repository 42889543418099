import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  Td,
  Text,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import TableView from "../../components/TableView";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import LinkBtn from "../../components/LinkBtn";
import store from "../../redux/store";
import { getMessages } from "../../redux/slice/messageSlice";
import CustomPopup from "../../components/CustomPopup";
import CustomInput from "../../components/CustomInput";
import TableHeader from "../../components/TableHeader";
import { message_action, query_status_update_api } from "../../utils/apis";
import CustomButton from "../../components/CustomButton";
import { FiEdit3, FiEye } from "react-icons/fi";
import CustomToolTip from "../../components/CustomTooltip";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { GrUpdate } from "react-icons/gr";
import { exportPDF } from "../../utils/utils";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { upload_url } from "../Blogs/Blogs";

const Messages = () => {
  const navigate = useNavigate();
  const [initialValue, setInitialValue] = useState(1);
  const [rows, setRows] = useState(10);
  const [page, setpage] = useState(1);
  const { message_list, loading, total_message, total_message_list } =
    useSelector((state) => state.messages);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [mess_data, setMess_data] = useState([]);
  const {
    isOpen: isCOpen,
    onOpen: onCOpen,
    onClose: onCClose,
  } = useDisclosure();
  const {
    isOpen: isCOpen1,
    onOpen: onCOpen1,
    onClose: onCClose1,
  } = useDisclosure();
  const [data, setData] = useState({});
  const [loading1, setLoading1] = useState(false);
  const [text, setText] = useState("");
  const [id, setId] = useState("");
  const [value, setValue] = useState("All");
  const [search, setSearch] = useState("");
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  const [openModal, setOpenModal] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [listData, setlistData] = useState([]);
  const [showAtt, setshowAtt] = useState(false);
  const [attUrl, setAttUrl] = useState("");
  useEffect(() => {
    setlistData(message_list);
  }, [message_list]);
  console.log(message_list);
  useEffect(() => {
    handleInputChange();
  }, [search]);

  // const status_update = async (id, status) => {

  // };

  const handleInputChange = () => {
    if (value === "All") {
      setpage(1);
      const inputText = search.toLowerCase();
      const filteredResults = message_list.filter((item) =>
        Object.values(item).some((val) =>
          String(val).toLowerCase().includes(inputText)
        )
      );
      setlistData(filteredResults);
    } else if (value === "Name") {
      const filterData = message_list.filter((user) =>
        user.full_name.toLowerCase().includes(search.toLowerCase())
      );
      setlistData(filterData);
    } else if (value === "Email") {
      const filterData = message_list.filter((user) =>
        user.email_address.toLowerCase().includes(search.toLowerCase())
      );
      setlistData(filterData);
    } else if (value === "Mobile") {
      const filterData = message_list.filter((user) =>
        user.mobile.toLowerCase().includes(search.toLowerCase())
      );
      setlistData(filterData);
    }
  };

  const handleExcel = (data) => {
    if (data?.length > 0) {
      const ws = XLSX.utils.json_to_sheet(data.map((v) => v));

      // Check if ws["!ref"] is defined before using it
      const firstRowRange = ws["!ref"]
        ? XLSX.utils.decode_range(ws["!ref"])
        : null;

      if (firstRowRange) {
        for (let col = firstRowRange.s.c; col <= firstRowRange.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({
            r: firstRowRange.s.r,
            c: col,
          });
          ws[cellAddress].s = { fill: { fgColor: { rgb: "DDDDDD" } } }; // Gray color
        }

        for (let col = firstRowRange.s.c; col <= firstRowRange.e.c; col++) {
          let maxCellLength = 30;

          for (
            let row = firstRowRange.s.r + 1;
            row <= firstRowRange.e.r;
            row++
          ) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            const cellValue = ws[cellAddress] ? ws[cellAddress].w || "" : "";
            maxCellLength = Math.max(maxCellLength, cellValue.length);
          }

          const colWidth = maxCellLength + 1; // You can adjust this value based on your needs
          ws["!cols"] = ws["!cols"] || [];
          ws["!cols"][col] = { width: colWidth };
        }

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
          mimeType: "application/octet-stream",
        });
        const blob = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        saveAs(blob, "your_file_name.xlsx");
      } else {
        toast.error("Please Add Some Item");
      }
    }
  };

  useEffect(() => {
    setMess_data(message_list);
    store
      .dispatch(getMessages(user?.consumer?.token))
      .then(unwrapResult)
      .then((v) =>
        setMess_data(v.message == "No Data available" ? [] : v.message)
      );
  }, []);
  const update = () => {
    onCClose();
    const body = new FormData();
    body.append("policy", "comment");
    body.append("id", id);
    body.append("comment", text);
  };

  const table_data = listData.map((elt, i) => [
    i + 1,
    elt.full_name,
    elt.email_address,
    elt.mobile,
    elt.status == 0 ? "InProcess" : "Done",
    elt.description,
  ]);
  const export_pdf = () => {
    exportPDF({
      table_data: table_data,
      table_headers: ["S.No.", "Name", "Email", "Contact", "Status", "Comment"],
      fileName: "Query-list",
      tableName: "SD 365 Query List",
    });
  };
  const headers = [
    { label: "S.No.", key: "no" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Contact", key: "mobile" },
    { label: "Status", key: "status" },
    { label: "Assigned", key: "assigned" },
    { label: "Comment", key: "comment" },
  ];
  const CSVdata = message_list.map((elt, i) => ({
    no: i + 1,
    name: elt.full_name,
    email: elt.email_address,
    mobile: elt.mobile,
    status: elt.status == 0 ? "InProcess" : "Done",
    comment: elt.description,
  }));

  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Heading fontSize={"xl"}>Query List</Heading>
      <Spacer h={3} />
      <Flex gap={2} align={"center"} mb={1}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Query:
        </Text>
        <Text fontWeight={"semibold"} fontSize={"xl"} color={"#000"}>
          {message_list?.length}
        </Text>
      </Flex>

      <TableHeader
        CSVdata={CSVdata}
        headers={headers}
        filename={"message-list.csv"}
        onPdfClick={() => export_pdf()}
        data={["All", "Name", "Email", "Mobile", "Status"]}
        onChange={(v) => setValue(v.target.value)}
        value={search}
        onSerchChange={(v) => {
          setpage(1);
          setSearch(v.target.value);
        }}
        handleExcel={() => handleExcel(listData)}
      />
      {loading && listData.length === 0 ? (
        <Loader />
      ) : (
        <TableView
          headData={["S.No.", "Name", "Email", "Contact", "Status", "Comment"]}
          body={
            <>
              {listData?.slice(rows * page - rows, rows * page).map((v, i) => (
                <Tr key={i}>
                  <Td textAlign={"center"}>{rows * page - rows + i + 1}</Td>
                  <Td
                    textAlign={"center"}
                    cursor={"pointer"}
                    onClick={() => {}}
                  >
                    {v?.full_name}
                  </Td>
                  <Td textAlign={"center"}>{v?.email_address}</Td>
                  <Td textAlign={"center"}>{v?.mobile}</Td>
                  <Td textAlign={"center"} w={"36"}>
                    <Select
                      w={"32"}
                      size={"sm"}
                      value={v?.status || "1"}
                      borderRadius={"md"}
                      onChange={async (e) => {
                        try {
                          const obj = {
                            query_id: v?.query_id,
                            status: e.target.value,
                          };
                          const response = await query_status_update_api(obj);
                          if (response.success) {
                            toast.success(response.message);
                            store
                              .dispatch(getMessages(user?.consumer?.token))
                              .then(unwrapResult)
                              .then((v) =>
                                setMess_data(
                                  v.message == "No Data available"
                                    ? []
                                    : v.message
                                )
                              );
                          } else {
                            toast.error(response.message);
                          }
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      <option value="1">Received</option>
                      <option value="2">In Process</option>
                      <option value="3">Complete</option>
                      <option value="4">Invalid Request</option>
                    </Select>
                  </Td>
                  <Td cursor={"pointer"} textAlign={"center"}>
                    <Text
                      color={"blue"}
                      textDecoration={"underline"}
                      cursor={"pointer"}
                      onClick={() => {
                        setOpenModal(true);
                        setMessageData(v?.description);
                        if (v?.file_path) {
                          setshowAtt(true);
                          setAttUrl(v?.file_path);
                        }
                      }}
                    >
                      View
                    </Text>
                  </Td>
                </Tr>
              ))}
            </>
          }
          pagination={true}
          rows={rows}
          page={page}
          initialValue={initialValue}
          onChange={(v) => {
            setRows(v.target.value);
            setInitialValue(1);
            setpage(1);
          }}
          onPrevious={() => {
            initialValue == 1 ||
              setInitialValue(Number(initialValue) - Number(rows));
          }}
          onNext={() => {
            rows * page >= total_message ||
              setInitialValue(Number(initialValue) + Number(rows));
            rows * page >= total_message || setpage(page + 1);
          }}
          total_value={message_list?.length}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Message Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <Heading fontSize={["md", "lg", "xl"]}>{data.name}</Heading>
              <Text fontSize={["sm", "md", "lg"]} my={2}>
                {data.message}
              </Text>
              <Text fontSize={["xs", "sm", "md"]} textAlign={"end"}>
                {data.datetime}
              </Text>
            </Flex>
            <Flex></Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <CustomPopup
        isOpen={isCOpen1}
        onClose={onCClose1}
        title={"Comment"}
        single_button
        mess={
          <Stack>
            {text.length == 0 ? (
              <Text>No Comment, Please add comment</Text>
            ) : (
              <Text>{text}</Text>
            )}
          </Stack>
        }
      />
      <CustomPopup
        isOpen={isCOpen}
        onClose={onCClose}
        title={"Comment"}
        b_name={"Update"}
        onClick={update}
        b_color={"#FFBF00"}
        mess={
          <Stack>
            {text.length == 0 && <Text>No Comment, Please add comment</Text>}
            <CustomInput
              area1
              onlyone
              value1={text}
              onChange1={(e) => setText(e.target.value)}
              placeholder1={"Enter Comment..."}
            />
          </Stack>
        }
      />
      {openModal && (
        <Modal
          title={"Comment"}
          open={openModal}
          footer={false}
          onCancel={() => setOpenModal(false)}
          width={"50%"}
        >
          <div>
            {messageData}
            {attUrl && <img src={`${upload_url}${attUrl}`} alt="" />}
          </div>
        </Modal>
      )}
    </Container>
  );
};

export default Messages;
