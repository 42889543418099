import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ID, base_url } from "../../utils/utils";
const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);
export const getNotificationlist = createAsyncThunk(
  "notifications",
  async (data) => {

    const response = await fetch(base_url + "admin-notification/list", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${data}`,
      }
    });
    const res = await response.json();
    return res;
  }
);

const notificationSlice = createSlice({
  name: "notifications",
  initialState: {
    notification_list: [],
    loading: false,
    total_notification: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(getNotificationlist.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNotificationlist.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getNotificationlist.fulfilled, (state, action) => {
      const data = action.payload;
      state.loading = false;
      state.total_notification = data.data.length;
      if (data.success) {
        state.notification_list = data.data;
      } else {
        state.notification_list = [];
      }
    });
  },
});

export default notificationSlice.reducer;
