import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ID, base_url } from "../../utils/utils";
import store from "../store";
const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);



const documentSlice = createSlice({
  name: "documents",
  initialState: {
    documents_list: [],
    d_loading: false,
    all_users_documents: [],
    all_users_documents_list: [],
    all_doc_loading: false,
    all_doc_count: 0,
  },
  extraReducers: (builder) => {
  },
});

export default documentSlice.reducer;
