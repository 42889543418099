import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ID, base_url } from "../../utils/utils";
import store from "../store";
import { toast } from "react-toastify";
const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);

const claimSlice = createSlice({
  name: "claims",
  initialState: {
    claim_list: [],
    c_loading: false,
    delete_loading: false,
    add_loading: false,
    update_loading: false,
  },
  extraReducers: (builder) => {

  },
});

export default claimSlice.reducer;
