import {
  Button,
  Card,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  Spacer,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { CSVLink } from "react-csv";
import { FiFilter } from "react-icons/fi";

const TableHeader = ({
  onPdfClick,
  data = [],
  onChange,
  value,
  onSerchChange,
  onCsvClick,
  CSVdata = [],
  headers = [],
  filter,
  filename,
  options,
  date_range,
  handleExcel,
  onBlockBlurData 
}) => {

  
//   var data = [
//     { id: 1, value: 'Item 1 ', status: { item: '가지마' } },
//     { value: 'Item 2', status: { item: 'благодарю вас' } },
//     { value: 'Item 3 \'quotes\'', id: 3, status: { item: 'Enabled' } },
//     { id: 4, value: 'Item 4 "quotes"', extra: 'ignored field' }
// ];

// // set headers
// var headers = [
//     { label: 'Identificator', field: 'id' },
//     { label: 'Description', field: 'value' },
//     { label: 'Status', field: 'status.item' }
// ]

// const handleExcel = ()=>{
//   toExcel.exportXLS(headers, data, 'filename');
// }
  return (
    <Card
      p={3}
      mb={2}
      flexDirection={["column", "row"]}
      alignItems={["start", "center"]}
      gap={1}
    >
      {/* <CustomButton title={"Export"} onClick={onClick} /> */}
      <Popover>
        <PopoverTrigger>
          <Button
            color={"#fff"}
            bg={"#FFBF00"}
            _hover={{ bg: "#FFBF0099" }}
            fontWeight={"bold"}
            h={8}
          >
            Export
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent w={"150px"}>
            <PopoverArrow />
            <PopoverBody>
              <Stack align={"flex-start"}>
                <Button variant={"link"} onClick={onPdfClick}>
                  PDF
                </Button>
                {/* <CSVLink data={CSVdata} filename={filename} headers={headers}>
                 
                </CSVLink> */}
                <Button onClick={handleExcel} variant={"link"}>
                    Excel
                    {/* <Download dataSet1 = {CSVdata} dataSet2={headers}  /> */}
                    </Button>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      <Spacer />
      {filter || (
        <Select
        onBlur={onBlockBlurData}
          icon={<FiFilter />}
          // placeholder="All"
          w={"40"}
          borderColor={"#000"}
          onChange={onChange}
        >
          {options
            ? options
            : data.map((v, i) => <option value={v}>{v}</option>)}
        </Select>
      )}
      <Spacer maxW={1} />
      {date_range || (
        <Input
          placeholder="Search user..."
          w={"80"}
          value={value}
          onChange={onSerchChange}
          
        />
      )}
    </Card>
  );
};

export default TableHeader;
