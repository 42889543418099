import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";


const providerSlice = createSlice({
  name: "provider",
  initialState: {
    provider_list: [],
    p_loading: false,
  },
  extraReducers: (builder) => {
   
  },
});

export default providerSlice.reducer;
