import {
  Button,
  Card,
  Container,
  Flex,
  FormLabel,
  Heading,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { block_user_api } from "../../utils/apis";
import { useLocation, useNavigate } from "react-router-dom";
import CustomInput from "../../components/CustomInput";
import TitleValue from "../../components/TitleValue";
import Loader from "../../components/Loader";
import CustomButton from "../../components/CustomButton";
import { BiArrowBack } from "react-icons/bi";
import { toast } from "react-toastify";
import moment from "moment";
import store from "../../redux/store";
import { getUsers } from "../../redux/slice/usersSlice";
import { Switch } from "antd";
import { base_url } from "../../utils/utils";

const UserDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showList, setShowList] = useState(false);
  const [showList1, setShowList1] = useState(false);
  const [data, setdata] = useState({});
  const [block, setBlock] = useState(false);
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  const [plan_details, setPlan_details] = useState({});
  const [recharge_list, setRecharge_list] = useState([]);

  useEffect(() => {
    setdata(location.state);
    setBlock(location.state.blocked);
    get_Plan_details();
    get_recharge_details();
  }, []);

  const get_Plan_details = async () => {
    try {
      setLoading(true);
      const responce = await fetch(
        base_url + `user/get-user-subcription-info/${location.state.user_id}`,
        {
          headers: {
            Authorization: `Bearer ${user.consumer?.token}`,
          },
          method: "GET",
        }
      );
      const res = await responce.json();
      setLoading(false);
      if (res.success) {
        setPlan_details(res.data);
      } else {
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const get_recharge_details = async () => {
    try {
      setLoading(true);
      const responce = await fetch(
        base_url + `recharge/list/user_id/${location.state.user_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${user.consumer?.token}`,
          },
        }
      );
      const res = await responce.json();
      console.log("data", res);
      setLoading(false);
      if (res.success) {
        setRecharge_list(res.list);
      } else {
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>User Detail</Heading>
        <CustomButton
          title={"Back"}
          icon={<BiArrowBack />}
          onClick={() => navigate(-1)}
        />
      </Flex>
      <Spacer h={3} />
      {loading ? (
        <Loader />
      ) : (
        <Card p={5} borderRadius={10} shadow={"base"}>
          <Flex justify={"end"} gap={5}>
            <Flex alignItems={"center"}>
              <FormLabel htmlFor="email-alerts" mb="0">
                Block
              </FormLabel>
              <Switch
                checked={Boolean(block)}
                onChange={async (v) => {
                  setBlock(!Boolean(block));
                  try {
                    const obj = {
                      user_id: data?.user_id,
                      Status: !block,
                    };
                    const response = await block_user_api(obj);
                    if (response.success) {
                      store.dispatch(getUsers(user?.consumer?.token));
                      toast.success(response.message);
                      // navigate("/users");
                    } else {
                      toast(response?.message);
                    }
                  } catch (error) {}
                }}
              />
            </Flex>
          </Flex>
          <CustomInput
            label1={"Name"}
            label2={"Email"}
            value1={data.full_name}
            value2={data.email_address}
            textonly1
            textonly2
          />
          <CustomInput
            label1={"Mobile"}
            label2={"Date Time"}
            value1={data.mobile}
            value2={moment(data.updated_at).format("DD/MM/YYYY")}
            textonly1
            textonly2
          />

          <CustomInput
            textonly1
            textonly2
            label1={"Plan Name"}
            label2={"Total Policy Limit"}
            value1={plan_details?.planInfo?.plan_name}
            value2={plan_details?.totalPdfLimit}
          />
          <CustomInput
            textonly1
            textonly2
            label1={"Used Limit"}
            label2={"Left Limit"}
            value1={plan_details?.usedLimit}
            value2={plan_details?.leftLimit}
          />
          <CustomInput
            textonly1
            textonly2
            label1={"Plan Price"}
            label2={"Plan Duration"}
            value1={plan_details?.planInfo?.plan_price}
            value2={plan_details?.planInfo?.plan_duration}
          />
          <Flex
            bg={"#00000004"}
            p={2}
            mt={3}
            borderRadius={5}
            alignItems={"center"}
            gap={5}
          >
            <Heading fontSize={"lg"} flex={1}>
              Payment Details
            </Heading>
            <Button
              alignSelf={"flex-start"}
              h={8}
              onClick={() => setShowList1(!showList1)}
              variant={"link"}
              color={"#000"}
            >
              Show Details
            </Button>
          </Flex>
          {showList1 && (
            <SimpleGrid minChildWidth={"sm"} gap={5} mt={4}>
              {recharge_list?.length == 0 ? (
                <Text>No Recharge data</Text>
              ) : (
                recharge_list?.map((v, i) => (
                  <Stack
                    key={i}
                    w={"full"}
                    border={"1px solid #00000033"}
                    borderRadius={5}
                    px={5}
                    pb={3}
                  >
                    <TitleValue title={"Full Name"} value={v?.FullName} />
                    <TitleValue title={"Email ID"} value={v?.Email_ID} />
                    <TitleValue
                      title={"Mobile Number"}
                      value={v?.Mobile_Number}
                    />
                    <TitleValue title={"City"} value={v?.City} />
                    <TitleValue title={"Pincode"} value={v?.Pincode} />
                    <TitleValue title={"Amount"} value={v?.Amount} />
                    <TitleValue
                      title={"Recharge Date"}
                      value={moment(v?.created_at).format(
                        "DD/MM/YYYY"
                      )}
                    />
                  </Stack>
                ))
              )}
            </SimpleGrid>
          )}
          <Flex
            bg={"#00000004"}
            p={2}
            mt={3}
            borderRadius={5}
            alignItems={"center"}
            gap={5}
          >
            <Heading fontSize={"lg"} flex={1}>
              Policy List
            </Heading>
            <Button
              alignSelf={"flex-start"}
              h={8}
              onClick={() => setShowList(!showList)}
              variant={"link"}
              color={"#000"}
            >
              Show Policy
            </Button>
          </Flex>
          {showList && (
            <SimpleGrid minChildWidth={"sm"} gap={5} mt={4}>
              {data?.pdfInfo?.length == 0 ? (
                <Text>No Policy data</Text>
              ) : (
                data?.pdfInfo?.map((v, i) => (
                  <Stack
                    key={i}
                    w={"full"}
                    border={"1px solid #00000033"}
                    borderRadius={5}
                    px={5}
                    pb={3}
                  >
                    <TitleValue
                      title={"Policy Company"}
                      value={v?.file_details?.Insurance_company_name}
                    />
                    <TitleValue
                      title={"Policy Plan"}
                      value={v?.file_details?.Insurance_plan_name}
                    />
                    <TitleValue
                      title={"Policy Number"}
                      value={v?.file_details?.Insurance_policy_number}
                    />
                    <TitleValue
                      title={"Mobile Number"}
                      value={v?.file_details?.Policyholder_phone_number}
                    />{" "}
                    <TitleValue
                      title={"Email"}
                      value={v?.file_details?.Policyholder_emailid}
                    />
                    <TitleValue
                      title={"Expiry Date"}
                      value={moment(v?.file_details?.Policy_expiry_date).format(
                        "DD/MM/YYYY"
                      )}
                    />
                    {/* <TitleValue
                      title={"Policy File"}
                      value={
                        <Button
                          variant={"link"}
                          color={"#"}
                          onClick={() => {
                            setUrl(v.policy_file);
                            onOpen();
                          }}
                        >
                          View File
                        </Button>
                      }
                    /> */}
                  </Stack>
                ))
              )}
            </SimpleGrid>
          )}
        </Card>
      )}
    </Container>
  );
};

export default UserDetail;
