import { VStack } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { IoMdNotificationsOutline } from "react-icons/io";

import { FiUsers } from "react-icons/fi";
import { CiSettings } from "react-icons/ci";
import { BiMessage } from "react-icons/bi";
import { FaListUl } from "react-icons/fa";

const Sidebar = () => {
  const location = useLocation();
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  const role = user?.consumer?.role;

  return (
    <VStack align={"start"}>
      <Link
        to={"/"}
        className={
          location.pathname.split("/")[1] == "" ? "activelink" : "link"
        }
      >
        <RxDashboard size={20} />
        Dashboard
      </Link>
      <Link
        to={"/users"}
        className={
          location.pathname.split("/")[1] == "users" ? "activelink" : "link"
        }
      >
        <FiUsers size={20} />
        Users
      </Link>
      <Link
        to={"/messages"}
        className={
          location.pathname.split("/")[1] == "messages" ? "activelink" : "link"
        }
      >
        <BiMessage size={20} />
        Query
      </Link>
      <Link
        to={"/notifications"}
        className={
          location.pathname.split("/")[1] == "notifications"
            ? "activelink"
            : "link"
        }
      >
        <IoMdNotificationsOutline size={20} />
        Notification
      </Link>
      {role == "super-admin" && (
        <Link
          to={"/add-admin"}
          className={
            location.pathname.split("/")[1] === "add-admin"
              ? "activelink"
              : "link"
          }
        >
          <CiSettings size={20} />
          Admin List
        </Link>
      )}
      <Link
        to={"/subscription-plans"}
        className={
          location.pathname.split("/")[1] === "subscription-plans"
            ? "activelink"
            : "link"
        }
      >
        <FaListUl size={20} />
        Plan Details
      </Link>
      <Link
        to={"/blogs-list"}
        className={
          location.pathname.split("/")[1] === "blogs-list"
            ? "activelink"
            : "link"
        }
      >
        <FaListUl size={20} />
        Blogs
      </Link>
    </VStack>
  );
};

export default Sidebar;
