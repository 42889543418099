import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";
import store from "../store";

export const getMessages = createAsyncThunk("messages", async (data) => {
  const body = new FormData();
  const response = await fetch(base_url + "questions/list", {
    headers: {
      Authorization: `Bearer ${data}`,
    },
    method: "GET",
  });
  const res = await response.json();
  return res;
});

const messageSlice = createSlice({
  name: "messages",
  initialState: {
    message_list: [],
    total_message_list: [],
    loading: false,
    total_message: 0,
    Total: 0,
  },
  extraReducers: (builder) => {
    builder.addCase(getMessages.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getMessages.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getMessages.fulfilled, (state, action) => {
      const data = action.payload;
      state.loading = false;
      state.total_message = data.totalcount;
      state.Total = data.totalcount;
      if (data.success) {
        state.message_list = data?.data;
      } else {
        state.message_list = [];
      }
    });
  },
});

export default messageSlice.reducer;
