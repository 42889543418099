import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);

export const getBlogs = createAsyncThunk("blogs", async () => {
  const response = await fetch(base_url + "blogs/list", {
    headers: {
      Authorization: `Bearer ${user?.consumer?.token}`,
    },
    method: "GET",
    redirect: "follow",
  });
  const res = await response.json();
  return res;
});

const documentSlice = createSlice({
  name: "blogs",
  initialState: {
    blogs_list: [],
    blogs_loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getBlogs.pending, (state, action) => {
      state.blogs_loading = true;
    });
    builder.addCase(getBlogs.rejected, (state, action) => {
      state.blogs_loading = false;
    });
    builder.addCase(getBlogs.fulfilled, (state, action) => {
      const data = action.payload;
      state.blogs_loading = false;
      if (data.success) {
        state.blogs_list = data?.data;
      } else {
        state.blogs_list = [];
      }
    });
  },
});

export default documentSlice.reducer;
