import claimSlice from "./slice/claimSlice";
import otherSlice from "./slice/otherSlice";
import usersSlice from "./slice/usersSlice";
import messageSlice from "./slice/messageSlice";
import notificationSlice from "./slice/notificationSlice";
import providerSlice from "./slice/providerSlice";
import policylistSlice from "./slice/policylistSlice";
import employeeSlice from "./slice/employeeSlice";
import dashboardSlice from "./slice/dashboardSlice";
import documentSlice from "./slice/documentSlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import blogSlice from "./slice/blogSlice";
import planSlice from "./slice/planSlice";

const persistConfig = {
  key: "root",
  storage,
};
const rootReducer = combineReducers({
  plan: planSlice,
  blogs: blogSlice,
  claims: claimSlice,
  other: otherSlice,
  users: usersSlice,
  messages: messageSlice,
  notifications: notificationSlice,
  providers: providerSlice,
  policies: policylistSlice,
  employee: employeeSlice,
  dashboard: dashboardSlice,
  documents: documentSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

export default store;
export const persistor = persistStore(store);
