import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TableView from "../../components/TableView";
import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Spacer,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { getNotificationlist } from "../../redux/slice/notificationSlice";
import CustomButton from "../../components/CustomButton";
import moment from "moment";

const NotificationList = () => {
  const navigate = useNavigate();
  const { notification_list, loading, total_notification } = useSelector(
    (state) => state.notifications
  );
  const [initialValue, setInitialValue] = useState(1);
  const [rows, setRows] = useState(10);
  const [page, setpage] = useState(1);
  const loggedInUser = localStorage.getItem("userId");
  const user = JSON.parse(loggedInUser);
  useEffect(() => {
    store.dispatch(getNotificationlist(user?.consumer?.token));
  }, []);
  return (
    <Container h={"full"} w={"full"} maxW={"full"}>
      <Flex justify={"space-between"}>
        <Heading fontSize={"xl"}>Notification List</Heading>
        <CustomButton
          title={"Add Notification"}
          onClick={() => navigate("/notifications/add-notification")}
        />
      </Flex>
      <Flex gap={2} align={"center"}>
        <Text fontSize={"xl"} color={"#000"} fontWeight={"normal"}>
          Total Notification:
        </Text>
        <Text fontWeight={"semibold"} fontSize={"xl"} color={"#000"}>
          {total_notification}
        </Text>
      </Flex>
      <Spacer h={3} />
      {loading && notification_list.length === 0 ? (
        <Loader />
      ) : (
        <TableView
          headData={["S.No.", "Title", "Message", "schedule date"]}
          body={notification_list
            ?.slice(rows * page - rows, rows * page)
            ?.map((v, i) => (
              <Tr key={i}>
                <Td textAlign={"center"}>{i + initialValue}</Td>
                <Td textAlign={"center"}>{v.title}</Td>
                <Td textAlign={"center"}>{v.message}</Td>

                <Td textAlign={"center"}>
                  {moment(v?.send_at).format("DD/MM/YYYY h:mm a")}
                </Td>
              </Tr>
            ))}
          pagination={true}
          rows={rows}
          page={page}
          initialValue={initialValue}
          onChange={(v) => {
            setRows(v.target.value);
            setInitialValue(1);
            setpage(1);
          }}
          onPrevious={() => {
            initialValue == 1 ||
              setInitialValue(Number(initialValue) - Number(rows));
            rows * page <= total_notification || setpage(page - 1);
          }}
          onNext={() => {
            rows * page >= total_notification ||
              setInitialValue(Number(initialValue) + Number(rows));
            rows * page >= total_notification || setpage(page + 1);
          }}
          total_value={total_notification}
        />
      )}
    </Container>
  );
};

export default NotificationList;
