import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ID, base_url } from "../../utils/utils";
import store from "../store";
const loggedInUser = localStorage.getItem("userId");
const user = JSON.parse(loggedInUser);

export const getUsers = createAsyncThunk("users", async (token) => {
  const response = await fetch(
    base_url + "user/list?page=1&listPerPage=1000000",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    }
  );
  const res = await response.json();
  return res;
});

export const getAdmin = createAsyncThunk("admins", async (token) => {
  const response = await fetch(
    base_url + "user/admin/list?page=1&listPerPage=1000000",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    }
  );
  const res = await response.json();
  return res;
});

const usersSlice = createSlice({
  name: "users",
  initialState: {
    users_list: [],
    u_loading: false,
    t_loading: false,
    total_users: 0,
    total_users_list: [],
    admin_list: [],
    admin_loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state, action) => {
      state.u_loading = true;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.u_loading = false;
    });

    builder.addCase(getUsers.fulfilled, (state, action) => {
      const data = action.payload;
      state.u_loading = false;
      if (data.success) {
        state.users_list = data.data;
        state.total_users = data.data.length;
      } else {
        state.total_users = 0;
        state.users_list = [];
      }
    });

    builder.addCase(getAdmin.pending, (state, action) => {
      state.admin_loading = true;
    });
    builder.addCase(getAdmin.rejected, (state, action) => {
      state.admin_loading = false;
    });

    builder.addCase(getAdmin.fulfilled, (state, action) => {
      const data = action.payload;
      state.admin_loading = false;

      if (data.success) {
        state.admin_list = data.list;
      } else {
        state.admin_list = [];
      }
    });
  },
});

export default usersSlice.reducer;
